export class DynamicOrders {
    public SearchParams: any;
    public PAGE_SIZE: number;
    public loadedPages: any;
    public numItems: any;
    public searching: boolean = true;

    constructor(
        public params: any,
        public OrderResourceFactory: any,
        public StatusService: any,
        public loginService: any,
        public $timeout: any,
    ) {
        /* BINDABLE: DATA */
        this.SearchParams = params;
        this.PAGE_SIZE = 50;

        /* Get things rolling */
        this.reloadPages_();
    }

    getItemAtIndex(index:any, lazy:any) {
        var pageNumber = Math.floor(index / this.PAGE_SIZE);
        var page = this.loadedPages[pageNumber];
        lazy = lazy === undefined ? false : lazy;

        if (page) {
            return page[index % this.PAGE_SIZE];
        } else if (page !== null) {
            if (!lazy) {
                this.fetchPage_(pageNumber);
            }
        }
    };

    getLength() {
        return this.numItems;
    };

    setItemAtIndex(index:any, item:any) {
        var pageNumber = Math.floor(index / this.PAGE_SIZE);
        var page = this.loadedPages[pageNumber];

        // Only update the screen if the spot is currently loaded
        if (page) {
            page[index % this.PAGE_SIZE] = item;
        }
    };

    fetchPage_(pageNumber:number) {
        var self = this;
        self.searching = true;

        // Set the page to null so we know it is already being fetched.
        self.loadedPages[pageNumber] = null;

        var pageOffset = pageNumber * self.PAGE_SIZE;

        var sortField = this.SearchParams.sortField ? this.SearchParams.sortField : 'date_created';
        var sortDirection = this.SearchParams.sortDirection
            ? this.SearchParams.sortDirection
            : 'DESC';
        var sortType = 'order';

        self.$timeout(function () {
            // Filter out blank values, since we don't want to search by those
            for (var prop in self.SearchParams) {
                if (self.SearchParams.hasOwnProperty(prop)) {
                    self.SearchParams[prop] =
                        self.SearchParams[prop] === '' || self.SearchParams[prop] === null
                            ? undefined
                            : self.SearchParams[prop];
                }
            }

            self.SearchParams.offset = pageOffset;
            self.SearchParams.limit = self.PAGE_SIZE;
            self.SearchParams.sortField = sortField;
            self.SearchParams.sortDirection = sortDirection;
            self.SearchParams.sortType = sortType;
            // TODO: Append all of these values to the params object from this.SearchParams so we can send one big object
            self.OrderResourceFactory.getAll(
                self.SearchParams,
                {},
                function success(orders:any) {
                    self.loadedPages[pageNumber] = orders.data.rows;
                    self.numItems = orders.data.count;
                    self.searching = false;
                },
                function failure(err:any) {
                    console.log(err);
                    self.loadedPages[pageNumber] = [];
                    self.searching = false;
                }
            );
        });
    };

    reloadPages_() {
        /**
         * @type {!Object<?Array>} Data pages, keyed by page number (0-index).
         */
        this.loadedPages = {};

        /** @type {number} Total number of items. */
        this.numItems = 0;

        /** @const {number} Number of items to fetch per request. */
        this.PAGE_SIZE = 50;

        this.getItemAtIndex(0, false);
    };
}
